.ListCon {
  padding: 3rem ;
  padding-left: 48px !important;
  padding-right: 48px !important;
}
.ListImgCon {
  padding-right: 95px !important;
  margin-top: 5px;
}
.title {
  color: #033e3c;
  font-size: 78px;
  line-height: 80px;
  font-family: "Memphis Bold";
  padding-bottom: 40px;

}
.desc {
  color: #ffffff;
  font-size: 32px;
  line-height: 38px;
  font-family: "Grotesk";
  padding-right: 60px;
  width: 76%;
  
}
.desc a {
  color: #ffffff;
  font-weight: bolder;
}
.desc a:hover {
  color: #ffffff;
  font-weight: bolder;
}
.ListTitle {
  font-size: 48px;
  line-height: 52px;
  font-family: "Memphis Bold";
  color: #033e3c;
  width: 85% !important;

}
.Listdesc {
  font-size: 32px;
  line-height: 38px;
  font-family: "Grotesk";
  color: #033e3c;
  width: 85% !important;
}

@media only screen and (min-width: 810px) and (max-width: 1390px) {
  .desc {
    color: #ffffff;
    font-size: 20px;
    line-height: 28px;
    font-family: "Grotesk";
    padding-right: 0rem !important;
  }
  .desc a {
    color: #ffffff;
    font-weight: bolder;
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .desc {
    padding-right: 50px;
  }
  .ListImgCon {
    padding-right: 20px !important;
    padding-bottom: 50px !important;
  }
  .ListCon {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .ListTitle{
    font-size: 38px;
  }
  .Listdesc{
    font-size: 28px;
  }
  
}
@media only screen and (max-width: 800px) {
  .ListCon {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .ListCon:last-child {
    padding-bottom: 0px !important;
    
  }
  .ListImgCon {
    padding-right: 0px !important;
    margin: 0 auto;
    padding-bottom: 50px;
  }
  
  .title {
    color: #033e3c;
    font-size: 48px;
    line-height: 50px;
    font-family: "Memphis Bold";
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .ListTitle {
    font-size: 48px;
    line-height: 52px;
    width: 100% !important;
  }
  .Listdesc {
    font-size: 29px;
    line-height: 34px;
    width: 100% !important;
  }
  .desc{
    width: 100% !important;
  }
   .ListCon {
    padding: 0 !important;
  }
  
}
