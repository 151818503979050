.description{
    font-family: 'Grotesk';
    color:rgb(3, 62, 60);
    font-size: 32px;
    line-height: 33px;
}

.description a{
    color:rgb(3, 62, 60);
    font-size: 32px;
    line-height: 33px;
    font-weight: bolder;
}

.description a:hover{
    color:rgb(3, 62, 60);
}

/* Tablet screens */
@media only screen and (min-width:810px ) and (max-width: 1390px)  { 

    .description {
        font-size: 28px;
    }
}

/* Mobile screens */
@media only screen and (max-width: 800px)  {
   
    .description {
        font-size: 25px !important;
        line-height: 28px !important;
    }

    .description a{
        font-size: 25px !important;
        line-height: 28px !important;
    }
   
}