#card3element {
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 350px;
  padding: 10px;
  border-radius: 20px;
  background-color: white;
  margin: auto;
}

#card3Title {
  font-family: "Memphis Bold";
  font-size: 16px;
  color: #033e3c;
  padding-left: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  text-wrap: "wrap";
  text-transform: uppercase;
  height: 30%;
}

/* Display .customize-scroll-bar on screens of size sm and above */

@media only screen and (min-width: 380px) {
  #card3element {
    max-width: 300px;
    min-width: 150px;
  }
}

@media only screen and (min-width: 500px) {
  #card3Title {
    font-size: 18px;
    min-width: 270px;
  }
}

@media only screen and (min-width: 640px) {
  #card3element {
    max-width: 350px;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) {
  #card3element {
    padding: 15px;
    /* min-width: 350px; */
  }

  #card3Title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) {
  #card3element {
    max-width: 320px;
    min-width: 320px;
    padding: 20px;
  }

  #card3Title {
    text-align: center;
    font-size: 24px;
  }
}

@media only screen and (min-width: 1280px) {
  #card3element {
    max-width: 370px;
    min-width: 350px;
  }
}

@media only screen and (min-width: 1536px) {
}
