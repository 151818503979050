
.footer-container{
    width: 100%;
}

.gr-left-footer{
    background-color: rgb(3, 62, 60);
    padding: 100px 300px 50px 160px !important;
}

.gr-left-footer p{
    color: rgb(219, 255, 105);
    font-family: 'Memphis-Bold';
    font-size: 27px;
    line-height: 32px;
}

.gr-right-footer{
    background-color: rgb(254, 200, 197);
    padding: 100px 300px 50px 100px !important;
    display: flex;
    align-items: flex-end;
}

.gr-right-footer img{
    max-width: 150px;
    max-height: 50px;
    width: 100%;
    max-height: 100%;
}

.gr-logo{
    max-width: 220px;
    max-height: 160px;
    width: 100%;
    max-height: 100%;
}

.footer-elm a{
   display: flex;
   flex-direction: column;
   text-transform: uppercase;
}
.footer-elm a{
    text-decoration: none;
    color: rgb(3, 62, 60);
    font-family: 'Memphis-Bold';
    font-size: 18px;
    line-height: 28px;
}
.footer-elm a:hover{
    color: rgb(3, 62, 60);
}

.footer_eu .EU-logo {
    width: 100%;
    max-width: 320px;
    height: 100%;
    max-height: 60px;
    margin: 0;
}

.footer_eu .ministry-logo {
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 150px;
    margin: 0;
}

.footer_eu .ministry-logo2 {
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 150px;
    margin: 0;
}
.footer_eu .AKPAlogo {
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 150px;
    margin: 0;
}
.footer_logoContainer{
    background-color: white;
}
.footer_eu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 10px 200px 10px 160px !important;
  background-color: white;
  gap: 90px;
}
.footer_eu p{
  margin: 0;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Grotesk';
}
.footer_logoContainer p{
    /* padding-left: 235px; */
    font-size: 14.5px;
    line-height: 27px;
    font-family: 'Grotesk';
    text-align: center;
}
.ministry-container{
    display:flex;
    gap: 90px;
}

/* Tablet screens */
@media only screen and (min-width:810px ) and (max-width: 1390px)  {
    .gr-right-footer{
        padding: 100px 100px 50px 100px !important;
    }
    .gr-left-footer{
        padding: 100px 100px 50px 160px !important;
    }
    .footer_eu{
        gap: 30px !important;
    }
}
@media only screen and (min-width:850px ) and (max-width: 1200px)  {
    .gr-left-footer{
        padding: 50px !important;
    }
    .gr-right-footer{
        padding: 50px !important;
    }
    .footer_eu {
        padding: 50px !important;
    }
    .footer_eu p{
        font-size: 17px;
        line-height: 26px;
    }
    .footer_logoContainer p{
        padding-left: 3rem;
        text-align: start;
    }
    .footer_eu{
        gap: 30px !important;
    }
}
@media only screen and (max-width: 800px)  {
    .program-img img{
        max-width: 100%;
        width: 500px !important;
        height: auto;
    }
    .gr-left-footer{
        padding: 50px !important;
    }
    .gr-right-footer{
        padding: 50px !important;
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    .gr-logo{
        max-width: 150px;
        width: 100%;
        max-height: 100%;
    }
    .gr-left-footer p{
        margin-left: 0px;
        font-size: 19px;
    }
    .footer-elm {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 10px;
    }    
    .footer-elm a{
       font-size: 11px !important;
    }    
    .footer-elm a:nth-child(5){
       grid-column: 2;
       grid-row: 1;
    }    
    .footer-elm a:nth-child(3){
       grid-column: 1;
       grid-row: 3;
    }    

    .footer_eu {
        display: grid !important;
        grid-template-rows: auto auto;
        grid-template-columns: auto auto;
        justify-content: center; /* Centers the grid horizontally */
        align-items: center;
        column-gap: 40px;
        row-gap: 20px; /* Gap between grid items */
        /* padding: 10px !important; */
        padding: 40px 2rem 40px 3rem !important;
    }

    .footer_eu p{
        font-size: 16px;
        line-height: 25px;
    }
    .footer_logoContainer p{
        padding-left: 3rem;
        padding-right: 3rem;
        text-align: start;
    }

    .footer_eu .ministry-logo {
        width: 100%;
        min-width: 100px;
        height: 100%;
        max-height: 150px;
        margin: 0;
    }
    
    .footer_eu .ministry-logo2 {
        width: 100%;
        min-width: 100px;
        height: 100%;
        max-height: 150px;
        margin: 0;
    }
    .footer_eu .AKPAlogo {
        width: 100%;
        max-width: 100px;
        height: 100%;
        max-height: 150px;
        margin: 0;
    }
    .footer_eu .EU-logo {
        width: 100%;
        min-width: 150px;
        height: 100%;
        max-height: 60px;
        margin: 0;
        object-fit: contain;
    }
    .ministry-container{
        display:flex;
        gap: 20px;
    }    
    
}