.image img{
    width: 100%;
}

.practiceCon{
    background-color: #F8F6F0;
    padding: 0 !important;
}

.practiceDesc{
    padding: 50px 75px 100px 100px !important;
    line-height: 38px;
}


.practiceInfo{
    padding: 100px 350px 100px 290px;
    color: #033E3C;
    background-color: #F8F6F0;
    font-size: 32px;
    line-height: 38px;
    font-family: 'Grotesk';
}
.rightSection img{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}
.VetepunsimPhoto{
    max-height: 715px !important;
}
.ListContainer{
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #F8F6F0;
}
.ListContainer p{
    text-align: center;
    font-size: 55px;
    line-height: 60px;
    color: #FE4B00;
    font-family: 'Memphis Bold';
    padding-bottom: 30px;
}
.ListContainer:last-child{
    padding-bottom: 80px;
}

.ListContainerText p{
    color: #033E3C;
    font-size: 32px !important;
    line-height: 38px !important;
    font-family: 'Grotesk';
    text-align: left;
    margin: 0 auto !important;
    padding-right: 550px;
    padding-left: 550px;
}

.ListParaCon{
    position: relative;
    height: 150px;
    background-color: #F8F6F0;
}

.Listparag{
    font-size: 32px;
    line-height: 38px;
    font-family: "Grotesk";
    color: #033E3C;
    background-color: #F8F6F0;
    margin: 0;
    position: absolute;
    top: -10%;
    left: 53%;
    transform: translate(-50%, -50%);
}
@media (min-width: 992px) {
    .desktopFixed {
      position: fixed;
      width: 100%;
      z-index: 99999999999999;
      top: 0;
    }
    .image{
        margin-top: 8rem;
    }
  }
  
@media only screen and (min-width:810px ) and (max-width: 1390px)  {
    .ListContainer :nth-child(1){
        padding-bottom: 40px;
    }
    .ListContainerText p{
        font-size: 28px !important;
        line-height: 30px !important;
        padding-right: 100px;
        padding-left: 100px;
    }
    .Listparag{
        font-size: 32px;
        line-height: 38px;
        font-family: "Grotesk";
        color: #033E3C;
        background-color: #F8F6F0;
        margin: 0;
    }
    .practiceDesc{
        padding: 50px 50px 50px 50px !important;
        line-height: 38px;
    }
    .practiceInfo{
        padding: 50px 5rem 50px 5rem;
    }
    .VetepunsimPhoto{
        max-height: 770px !important;
    }
}

/* Mobile devices */
@media only screen and (max-width: 800px)  {
    .practiceDesc{
        padding: 50px 3rem !important;
        
    }
    .rightSection img{
        width: 100%;
    }
    .practiceBtn{
        padding-top: .5rem !important;
    }
    .practiceInfo{
        padding: 50px 3rem;
        font-size: 23px;
        line-height: 30px;
    }
    .ListContainer :nth-child(1){
        padding-bottom: 20px;
    }
    .ListContainer{
       padding-left: 3rem;
       padding-right: 2rem;
    }
    .ListContainer p{
        font-size: 32px;
        line-height: 35px;
        padding-bottom: 50px;
    }
    .ListContainerText p{
        font-size: 25px !important;
        line-height: 28px !important;
        padding-right: 0px;
        padding-left: 0px;
    }
    .ListParaCon{
        position: relative;
        height: 350px;
        background-color: #F8F6F0;
        padding: 0;
        margin: 0;
    }
    .ListparagMobile{
        font-size: 28px !important;
        line-height: 30px !important;
        padding-right: 0px;
        padding-left: 0px;
        padding-left: 3rem;
        padding-right: 2rem;
        margin-top: -13rem;
    }
}
