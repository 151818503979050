#element {
  height: 150px;
  flex: 1;
  flex-basis: 25%;
  max-width: 160px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  align-items: center;
  gap: 10px;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  text-wrap: wrap;
}

#activeElement {
  height: 150px;
  flex: 1;
  flex-basis: 25%;
  max-width: 160px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  align-items: center;
  gap: 10px;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  text-wrap: wrap;
  background-color: rebeccapurple;
}

.active {
  color: black;
}

#imageContainer {
  width: 100%;
  height: 65%;
  padding: 5px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: end;
}
