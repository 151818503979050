#container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100vh; */
}

#mainSection {
  width: 100%;
  /* height: 100vh; */
  /* flex: 1; */
  height: 72vh;
}

#sliderMainContainer {
  width: 100%;
  /* height: 35%; */
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: end;
  position: relative;
}

#swiper {
  /* width: 50%; */
  width: 84%;
  position: absolute;
  /* background-color: red; */
  left: 0;
  right: 0;
  margin: auto;
  bottom: -25px;
  z-index: 99999;
}

#swiperSlideElement {
  max-width: 250px;
  max-height: 250px;
}

#swiperButtonNextt {
  position: absolute;
  cursor: pointer;
  top: 120%;
  right: 10%;
}

#swiperButtonPrevv {
  position: absolute;
  top: 120%;
  left: 10%;
  cursor: pointer;
}

#eventsDetailsContainer {
  width: 100%;
  /* height: 37%; */
  height: 37vh;
  background-color: #8f68cb;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  /* gap: 20px; */
  text-align: center;
  font-family: "Memphis Normal";
}

#title {
  /* padding-top: 150px; */
  font-size: 35px;
  color: #dbff69;
  width: 100%;
  max-width: 550px;
  text-transform: uppercase;
  font-family: "Memphis Bold";
  line-height: 35px;
  height: 60%;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#locationDataTimeContainer {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 60px;
  font-size: 14px;
  color: white;
  /* text-wrap: nowrap; */
  /* background-color: black; */
  /* font-family: "Memphis Normal"; */
}

#location {
  display: flex;
  gap: 5px;
  font-family: "Memphis Normal";
}

#data {
  font-family: "Memphis Normal";
}

#time {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: "Memphis Normal";
}

#categories {
  width: 100%;
  background-color: #b382fe;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#eventetSotSection {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 110px;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
  margin: auto 0px;
}

#eventetSotTitle {
  font-family: "Memphis Extra Bold";
  font-size: 25px;
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
}

#eventetSotCardDescription p {
  color: #b5b5b5;
  font-family: "Memphis Normal";
}

.sliderContainer {
  width: 100%;
  margin: auto;
  /* display: block; */
  /* justify-content: center;
  align-items: center; */
}

#sliderMain {
  /* padding-left: 7px; */
  background-color: black;
  margin: auto;
}

#eventetSotElement {
  width: 100%;
  max-width: 350px;
  /* min-width: 280px; */
  min-width: 300px;
  height: 100%;
  /* max-height: 550px; */
  height: 450px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  margin: auto;
  /* margin-left: 120px; */
}

#sygjerimetTonaContainer {
  background-color: black;
  position: relative;
  height: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 10px;
}

#sygjerimetTona {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

#kategoriEventesh {
  background-color: #f8f5ee;
  position: relative;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 10px;
}

#kategoriSpecifikeContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}

#kategoriSpecifikeTitleSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  gap: 15px;
  /* padding-left: 125px; */
  /* padding-left: 7%; */
}

#kategoriSpecifikeTitle {
  font-family: "Memphis Extra Bold";
  font-size: 25px;
  text-transform: uppercase;
  color: #033e3c;
}

.slick-slider {
  margin: auto;
}

.slick-slide {
  padding: 10px;
  /* background-color: red; */
}

#nextBtn {
  box-shadow: 0px 3px 6px #00000029;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fe4b00;
  font-family: "Memphis Extra Bold";
  font-size: 20px;
  position: absolute;
  top: 90%;
  right: 35%;
  cursor: pointer;
  background-color: white;
}
#nextBtn:hover {
  opacity: 0.8;
}

#prevBtn {
  box-shadow: 0px 3px 6px #00000029;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fe4b00;
  font-family: "Memphis Extra Bold";
  font-size: 20px;
  position: absolute;
  top: 90%;
  left: 35%;
  cursor: pointer;
  background-color: white;
}
#prevBtn:hover {
  opacity: 0.8;
}

/* Display .customize-scroll-bar on screens of size sm and above */

@media only screen and (min-width: 424px) {
  #eventetSotElement {
    max-width: 370px;
    min-width: 300px;
    height: 450px;
    /* margin-left: 120px; */
  }
}

@media only screen and (min-width: 520px) {
}

@media only screen and (min-width: 640px) {
  #eventetSotElement {
    max-width: 300px;
    margin: auto;
  }

  #mainSection {
    height: 90vh;
  }

  #sliderMainContainer {
    height: 50vh;
  }

  #eventsDetailsContainer {
    height: 40vh;
  }

  /* #sliderMainContainer {
    height: 50%;
  }

  #eventsDetailsContainer {
    height: 40%;
  } */

  #swiper {
    width: 60%;
    bottom: -30px;
    /* bottom: -73px; */
  }

  #swiperSlideElement {
    max-width: 300px;
    max-height: 300px;

    /* max-width: 440px;
    max-height: 440px; */
  }

  #swiperButtonNextt {
    top: 60%;
    right: 10%;
    /* right: 18%; */
  }

  #swiperButtonPrevv {
    top: 60%;
    left: 10%;
    /* left: 18%; */
  }

  #title {
    font-size: 40px;
    line-height: 45px;
  }

  #locationDataTimeContainer {
  }

  #location {
  }

  #data {
  }

  #time {
  }
}

@media only screen and (min-width: 768px) {
  #eventetSotElement {
    max-width: 350px;
    margin: auto;
  }

  #swiper {
    width: 70%;
    bottom: -34px;
  }

  #swiperSlideElement {
    max-width: 340px;
    max-height: 340px;
  }

  #swiperButtonNextt {
    top: 60%;
    right: 5%;
  }

  #swiperButtonPrevv {
    top: 60%;
    left: 5%;
  }
}

@media only screen and (min-width: 1000px) {
  #eventetSotElement {
    max-width: 320px;
  }
}

@media only screen and (min-width: 1280px) {
  .sliderContainer {
    width: 90%;
    margin: auto;
  }

  #sliderMain {
    padding-right: 15px;
  }

  #eventetSotSection {
    padding-top: 60px;
    padding-right: 50px;
    padding-left: 50px;
  }

  #sygjerimetTonaContainer {
    padding-top: 60px;
    padding-right: 50px;
    padding-left: 50px;
  }
  #kategoriEventesh
  /* #kategoriSpecifikeContainer  */ {
    padding-top: 80px;
    padding-right: 50px;
    padding-left: 50px;
  }

  #sygjerimetTona {
    width: 100%;
  }

  #sliderMain {
    padding-left: 20px;
  }

  #eventetSotElement {
    max-width: 340px;
  }

  #nextBtn {
    top: 42%;
    right: 20px;
  }

  #prevBtn {
    top: 42%;
    left: 20px;
  }

  #mainSection {
    height: 95vh;
  }

  #sliderMainContainer {
    height: 55vh;
  }

  #eventsDetailsContainer {
    height: 40vh;
  }

  #swiper {
    width: 70%;
    bottom: -73px;
  }

  #swiperSlideElement {
    max-width: 440px;
    max-height: 440px;
  }

  #swiperButtonNextt {
    top: 60%;
    right: 7%;
  }

  #swiperButtonPrevv {
    top: 60%;
    left: 7%;
  }
}

@media only screen and (min-width: 1536px) {
  #eventetSotElement {
    max-width: 320px;
  }

  #mainSection {
    height: 95vh;
  }

  #sliderMainContainer {
    height: 55vh;
  }

  #eventsDetailsContainer {
    height: 40vh;
  }

  #sliderMain {
    padding-right: 10px;
  }

  #swiper {
    width: 50%;
    bottom: -73px;
  }

  /* #eventsDetailsContainer {
    height: 45%;
  } */

  #swiperButtonNextt {
    top: 60%;
    right: 18%;
  }

  #swiperButtonPrevv {
    top: 60%;
    left: 18%;
  }

  #kategoriSpecifikeTitleSection {
    justify-content: start;
    padding-left: 125px;
  }
}

@media only screen and (min-width: 1800px) {
  #eventetSotElement {
    max-width: 370px;
  }
}
