
#slider-testimonials {
  width: 100% !important;
  height: 100%;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  /* padding-left: 60px;
  padding-right: 60px; */
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

#slider-testimonials .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px !important;
  min-width: 300px !important;
  width: 100%;
}

#slider-testimonials .swiper-slide img {
  display: block;
  width: 100%;
  height: 355px;
  object-fit: cover;
}
#slider-testimonials  .swiper-slide video {
  display: block;
  width: 100%;
  height: 355px;
  object-fit: cover;
}
#slider-testimonials .swiper-slide:has(video){
  width: 600px !important;  
}
#slider-testimonials .swiper-slide:has(img){
  width: 300px !important;  
}
#slider-testimonials .swiper-button-next{
  position: absolute;
  right: -1px;
}
.left-arrow-homepage{
  width: 40px;
  height: 40px;
  background-color: rgb(249, 246, 240);
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50%;
  position: absolute;
  top: 28rem;
  z-index: 99;
  left: 12rem;
}
.right-arrow-homepage{
  width: 40px;
  height: 40px;
  background-color: rgb(249, 246, 240);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50%;
  position: absolute;
  right: 12rem;
  top: 28rem;
  z-index: 99;
}
.test-testimonials{
  overflow: hidden !important;
}
@media only screen and (min-width:810px ) and (max-width: 1390px)  {
  #slider-testimonials .swiper-slide:has(video){
    width: 300px !important;  
  }
  #slider-testimonials .swiper-slide video{
    width: 300px !important;  
  }
  #slider-testimonials .swiper-slide:has(img){
    width: 300px !important;  
  }
  .left-arrow-homepage{
    top: 12rem;
    left: 5rem;
  }
  .right-arrow-homepage{
    top: 12rem;
    right: 5rem;
  }
}

@media only screen and (max-width: 800px)  {
  
  #slider-testimonials {
    padding-left:40px;
    padding-right:45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #slider-testimonials .swiper-slide:has(video){
    width: 350px !important;  
  }
  #slider-testimonials .swiper-slide video{
    width: 350px !important;  
  }
  #slider-testimonials .swiper-slide img {
    display: block;
    width: 100%;
    height: 355px;
    object-fit: cover;
  }
  #slider-testimonials .swiper-slide:has(img){
    width: 350px !important;  
  }
  .left-arrow-homepage{
    left: 2rem;
  }
  .right-arrow-homepage{
    right: 18.5rem;
  }
}
@media only screen and (max-width: 390px)  {

  .right-arrow-homepage{
    right: 15rem;
  }
}
